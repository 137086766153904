.App {
  font-family: "Times New Roman", Times, serif;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  clear: both;
  font-size: 2vw;
}

.time-link {
  color: black;
  text-decoration: none;
}

.time-link:hover {
  text-decoration: none;
}

.time-link:visited {
  color: black;
}

.link {
  color: blue;
}

.link:visited {
  color: blue;
}

.bio {
  max-width: 60%;
}

.time {
  font-size: 11px;
}
