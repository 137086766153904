body {
  overflow: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-device-width: 0px) {
  body {
    font-size: 12px;
  }
  .image {
    width: 50%;
    height: auto;
  }

  .portrait {
    width: 30%;
    height: auto;
  }
}

@media screen and (min-device-width: 1300px) {
  body {
    font-size: 30px;
  }
  .image {
    width: 50%;
    height: auto;
  }
  .portrait {
    width: 18%;
    height: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
